var components
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-uni-view",
    {
      on: {
        touchmove: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          arguments[0] = $event = _vm.$handleEvent($event)
        },
      },
    },
    [
      _c(
        "v-uni-view",
        {
          staticClass: "yid-modal-box",
          class: [
            _vm.fadein || _vm.show ? "yid-modal-normal" : "yid-modal-scale",
            _vm.show ? "yid-modal-show" : "",
          ],
          style: {
            width: _vm.width,
            padding: _vm.padding,
            borderRadius: _vm.radius,
          },
        },
        [
          !_vm.custom
            ? _c(
                "v-uni-view",
                [
                  _vm.title
                    ? _c("v-uni-view", { staticClass: "yid-modal-title" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ])
                    : _vm._e(),
                  _c(
                    "v-uni-view",
                    {
                      staticClass: "yid-modal-content",
                      class: [_vm.title ? "" : "yid-mtop"],
                      style: { color: _vm.color, fontSize: _vm.size + "rpx" },
                    },
                    [_vm._v(_vm._s(_vm.content))]
                  ),
                  _c(
                    "v-uni-view",
                    {
                      staticClass: "yid-modalBtn-box",
                      class: [_vm.button.length != 2 ? "yid-flex-column" : ""],
                    },
                    [
                      _vm._l(_vm.button, function (item, index) {
                        return [
                          _c(
                            "v-uni-button",
                            {
                              key: index + "_0",
                              staticClass: "yid-modal-btn",
                              class: [
                                "yid-" +
                                  (item.type || "primary") +
                                  (item.plain ? "-outline" : ""),
                                _vm.button.length != 2 ? "yid-btn-width" : "",
                                _vm.button.length > 2 ? "yid-mbtm" : "",
                                _vm.shape == "circle" ? "yid-circle-btn" : "",
                              ],
                              attrs: {
                                "hover-class":
                                  "yid-" +
                                  (item.plain
                                    ? "outline"
                                    : item.type || "primary") +
                                  "-hover",
                                "data-index": index,
                              },
                              on: {
                                click: function ($event) {
                                  arguments[0] = $event =
                                    _vm.$handleEvent($event)
                                  _vm.handleClick.apply(void 0, arguments)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.text || "确定"))]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _c("v-uni-view", [_vm._t("default")], 2),
        ],
        1
      ),
      _c("v-uni-view", {
        staticClass: "yid-modal-mask",
        class: [_vm.show ? "yid-mask-show" : ""],
        on: {
          click: function ($event) {
            arguments[0] = $event = _vm.$handleEvent($event)
            _vm.handleClickCancel.apply(void 0, arguments)
          },
        },
      }),
    ],
    1
  )
}
var recyclableRender = false
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns, recyclableRender, components }

import Vue from 'vue'

const locales = {keys(){return []}}
global['____8F511EB____'] = true;
delete global['____8F511EB____'];
global.__uniConfig = {"easycom":{"^u-(.*)":"uview-ui/components/u-$1/u-$1.vue","^unicloud-db$":"@dcloudio/uni-cli-shared/components/unicloud-db.vue","^uniad$":"@dcloudio/uni-cli-shared/components/uniad.vue","^ad-rewarded-video$":"@dcloudio/uni-cli-shared/components/ad-rewarded-video.vue","^ad-fullscreen-video$":"@dcloudio/uni-cli-shared/components/ad-fullscreen-video.vue","^ad-interstitial$":"@dcloudio/uni-cli-shared/components/ad-interstitial.vue","^ad-interactive$":"@dcloudio/uni-cli-shared/components/ad-interactive.vue","^page-meta$":"@dcloudio/uni-cli-shared/components/page-meta.vue","^navigation-bar$":"@dcloudio/uni-cli-shared/components/navigation-bar.vue","^uni-match-media$":"@dcloudio/uni-cli-shared/components/uni-match-media.vue"},"globalStyle":{"navigationBarTextStyle":"black","navigationBarTitleText":"丽颜CRM","navigationBarBackgroundColor":"#fff","backgroundColor":"#fff","backgroundColorTop":"#F4F5F6","backgroundColorBottom":"#F4F5F6"},"condition":{"current":0,"list":[{"name":"","path":"","query":""}]}};
global.__uniConfig.compilerVersion = '3.99';
global.__uniConfig.darkmode = false;
global.__uniConfig.themeConfig = {};
global.__uniConfig.uniPlatform = 'h5';
global.__uniConfig.appId = '__UNI__8F511EB';
global.__uniConfig.appName = '丽颜CRM';
global.__uniConfig.appVersion = '1.0.41';
global.__uniConfig.appVersionCode = '105';
global.__uniConfig.router = {"mode":"hash","base":"/"};
global.__uniConfig.publicPath = "/";
global.__uniConfig['async'] = {"loading":"AsyncLoading","error":"AsyncError","delay":200,"timeout":20000};
global.__uniConfig.debug = false;
global.__uniConfig.networkTimeout = {"request":60000,"connectSocket":60000,"uploadFile":60000,"downloadFile":60000};
global.__uniConfig.sdkConfigs = {"maps":{}};
global.__uniConfig.qqMapKey = undefined;
global.__uniConfig.googleMapKey = undefined;
global.__uniConfig.aMapKey = undefined;
global.__uniConfig.aMapSecurityJsCode = undefined;
global.__uniConfig.aMapServiceHost = undefined;
global.__uniConfig.locale = "";
global.__uniConfig.fallbackLocale = undefined;
global.__uniConfig.locales = locales.keys().reduce((res,key)=>{const locale=key.replace(/\.\/(uni-app.)?(.*).json/,'$2');const messages = locales(key);Object.assign(res[locale]||(res[locale]={}),messages.common||messages);return res},{});
global.__uniConfig.nvue = {"flex-direction":"column"}
global.__uniConfig.__webpack_chunk_load__ = __webpack_chunk_load__
Vue.component('pages-workplace-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("C:/Users/椰岛/Desktop/wework/pages/workplace/index"+'.vue')), 'pages-workplace-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-customer-employeeList', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("C:/Users/椰岛/Desktop/wework/pages/customer/employeeList"+'.vue')), 'pages-customer-employeeList'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-customer-customerTags', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("C:/Users/椰岛/Desktop/wework/pages/customer/customerTags"+'.vue')), 'pages-customer-customerTags'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-customer-add', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("C:/Users/椰岛/Desktop/wework/pages/customer/add"+'.vue')), 'pages-customer-add'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-customer-search', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("C:/Users/椰岛/Desktop/wework/pages/customer/search"+'.vue')), 'pages-customer-search'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-customer-detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("C:/Users/椰岛/Desktop/wework/pages/customer/detail"+'.vue')), 'pages-customer-detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-returnVisit-visitLibrary', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("C:/Users/椰岛/Desktop/wework/pages/returnVisit/visitLibrary"+'.vue')), 'pages-returnVisit-visitLibrary'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-customer-list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("C:/Users/椰岛/Desktop/wework/pages/customer/list"+'.vue')), 'pages-customer-list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-customer-tags', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("C:/Users/椰岛/Desktop/wework/pages/customer/tags"+'.vue')), 'pages-customer-tags'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-returnVisit-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("C:/Users/椰岛/Desktop/wework/pages/returnVisit/index"+'.vue')), 'pages-returnVisit-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-returnVisit-memVisitList', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("C:/Users/椰岛/Desktop/wework/pages/returnVisit/memVisitList"+'.vue')), 'pages-returnVisit-memVisitList'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-customerImage-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("C:/Users/椰岛/Desktop/wework/pages/customerImage/index"+'.vue')), 'pages-customerImage-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-customerImage-RechargeCustomer', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("C:/Users/椰岛/Desktop/wework/pages/customerImage/RechargeCustomer"+'.vue')), 'pages-customerImage-RechargeCustomer'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-customerImage-rechargeCustomerNew', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("C:/Users/椰岛/Desktop/wework/pages/customerImage/rechargeCustomerNew"+'.vue')), 'pages-customerImage-rechargeCustomerNew'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-customer-components-analysis', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("C:/Users/椰岛/Desktop/wework/pages/customer/components/analysis"+'.vue')), 'pages-customer-components-analysis'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-returnVisit-visitList', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("C:/Users/椰岛/Desktop/wework/pages/returnVisit/visitList"+'.vue')), 'pages-returnVisit-visitList'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-returnVisit-visitDetail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("C:/Users/椰岛/Desktop/wework/pages/returnVisit/visitDetail"+'.vue')), 'pages-returnVisit-visitDetail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-returnVisit-visitStatistics', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("C:/Users/椰岛/Desktop/wework/pages/returnVisit/visitStatistics"+'.vue')), 'pages-returnVisit-visitStatistics'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-weworkVisit-shopVisitFine', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("C:/Users/椰岛/Desktop/wework/pages/weworkVisit/shopVisitFine"+'.vue')), 'pages-weworkVisit-shopVisitFine'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-returnVisit-visitDissatisfied', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("C:/Users/椰岛/Desktop/wework/pages/returnVisit/visitDissatisfied"+'.vue')), 'pages-returnVisit-visitDissatisfied'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-customer-creatReservation', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("C:/Users/椰岛/Desktop/wework/pages/customer/creatReservation"+'.vue')), 'pages-customer-creatReservation'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
global.__uniRoutes=[
{
path: '/',
alias:'/pages/workplace/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          isQuit:true,
isEntry:true,

          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-workplace-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
id:1,
  name:'pages-workplace-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/workplace/index',
isQuit:true,
isEntry:true,
  windowTop:0
}
},
{
path: '/pages/customer/employeeList',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-customer-employeeList', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-customer-employeeList',
  isNVue:false,maxWidth:0,
  pagePath:'pages/customer/employeeList',
  windowTop:0
}
},
{
path: '/pages/customer/customerTags',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-customer-customerTags', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-customer-customerTags',
  isNVue:false,maxWidth:0,
  pagePath:'pages/customer/customerTags',
  windowTop:0
}
},
{
path: '/pages/customer/add',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"添加会员","navigationBarBackgroundColor":"#fff","navigationBarTextStyle":"black","titleNView":{"buttons":[{"text":"","fontSrc":"/static/fonts/iconfont.ttf","fontSize":"44px","color":"#333"}]}})
      },
      [
        createElement('pages-customer-add', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-customer-add',
  isNVue:false,maxWidth:0,
  pagePath:'pages/customer/add',
  windowTop:44
}
},
{
path: '/pages/customer/search',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-customer-search', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-customer-search',
  isNVue:false,maxWidth:0,
  pagePath:'pages/customer/search',
  windowTop:0
}
},
{
path: '/pages/customer/detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-customer-detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-customer-detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/customer/detail',
  windowTop:0
}
},
{
path: '/pages/returnVisit/visitLibrary',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-returnVisit-visitLibrary', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-returnVisit-visitLibrary',
  isNVue:false,maxWidth:0,
  pagePath:'pages/returnVisit/visitLibrary',
  windowTop:0
}
},
{
path: '/pages/customer/list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-customer-list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-customer-list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/customer/list',
  windowTop:0
}
},
{
path: '/pages/customer/tags',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"维护标签","navigationBarBackgroundColor":"#fff","navigationBarTextStyle":"black"})
      },
      [
        createElement('pages-customer-tags', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-customer-tags',
  isNVue:false,maxWidth:0,
  pagePath:'pages/customer/tags',
  windowTop:44
}
},
{
path: '/pages/returnVisit/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-returnVisit-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-returnVisit-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/returnVisit/index',
  windowTop:0
}
},
{
path: '/pages/returnVisit/memVisitList',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-returnVisit-memVisitList', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-returnVisit-memVisitList',
  isNVue:false,maxWidth:0,
  pagePath:'pages/returnVisit/memVisitList',
  windowTop:0
}
},
{
path: '/pages/customerImage/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-customerImage-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-customerImage-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/customerImage/index',
  windowTop:0
}
},
{
path: '/pages/customerImage/RechargeCustomer',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-customerImage-RechargeCustomer', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-customerImage-RechargeCustomer',
  isNVue:false,maxWidth:0,
  pagePath:'pages/customerImage/RechargeCustomer',
  windowTop:0
}
},
{
path: '/pages/customerImage/rechargeCustomerNew',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-customerImage-rechargeCustomerNew', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-customerImage-rechargeCustomerNew',
  isNVue:false,maxWidth:0,
  pagePath:'pages/customerImage/rechargeCustomerNew',
  windowTop:0
}
},
{
path: '/pages/customer/components/analysis',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-customer-components-analysis', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-customer-components-analysis',
  isNVue:false,maxWidth:0,
  pagePath:'pages/customer/components/analysis',
  windowTop:0
}
},
{
path: '/pages/returnVisit/visitList',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-returnVisit-visitList', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-returnVisit-visitList',
  isNVue:false,maxWidth:0,
  pagePath:'pages/returnVisit/visitList',
  windowTop:0
}
},
{
path: '/pages/returnVisit/visitDetail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-returnVisit-visitDetail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-returnVisit-visitDetail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/returnVisit/visitDetail',
  windowTop:0
}
},
{
path: '/pages/returnVisit/visitStatistics',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-returnVisit-visitStatistics', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-returnVisit-visitStatistics',
  isNVue:false,maxWidth:0,
  pagePath:'pages/returnVisit/visitStatistics',
  windowTop:0
}
},
{
path: '/pages/weworkVisit/shopVisitFine',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-weworkVisit-shopVisitFine', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-weworkVisit-shopVisitFine',
  isNVue:false,maxWidth:0,
  pagePath:'pages/weworkVisit/shopVisitFine',
  windowTop:0
}
},
{
path: '/pages/returnVisit/visitDissatisfied',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-returnVisit-visitDissatisfied', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-returnVisit-visitDissatisfied',
  isNVue:false,maxWidth:0,
  pagePath:'pages/returnVisit/visitDissatisfied',
  windowTop:0
}
},
{
path: '/pages/customer/creatReservation',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-customer-creatReservation', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-customer-creatReservation',
  isNVue:false,maxWidth:0,
  pagePath:'pages/customer/creatReservation',
  windowTop:0
}
},
{
path: '/choose-location',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-choose-location', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'choose-location',
  pagePath:'/choose-location'
}
}
    ,
{
path: '/open-location',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-open-location', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'open-location',
  pagePath:'/open-location'
}
}
    ]
global.UniApp && new global.UniApp();
